
import dayjs from 'dayjs'

export default {
  name: 'MPMetaProductItem',

  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    showMerchantSeoMicroOffer() {
      return (
        this.$route.name?.includes('merchant-merchant___az') ||
        this.$route.name?.includes('merchant-merchant___ru')
      )
    },
    link() {
      return (
        this.currentRout +
        this.localePath({
          name: 'product-slug',
          params: {
            slug: `${this.product?.id || this.product?.product_id}-${
              this.product?.slugged_name
            }`,
          },
        })
      )
    },
    image() {
      return (
        this.product?.img_url_thumbnail ||
        this.product?.img_url_original ||
        this.product?.image_url
      )
    },
    productRating() {
      return this.product?.ratings?.rating_value || 0
    },
    productRatingCount() {
      return this.product?.ratings?.session_count || 0
    },
    currentRout() {
      let rout = 'https://umico.az'
      if (this.$config.ENVIRONMENT === 'Staging') {
        rout = 'https://mp-client-stage.umico.az'
      } else if (this.$config.ENVIRONMENT === 'Development') {
        rout = 'https://mp-client-dev.umico.az'
      } else if (this.$config.ENVIRONMENT === 'Testing') {
        rout = 'https://mp-client-test.umico.az'
      } else {
        rout = 'https://umico.az'
      }
      return rout
    },
    productAvailability() {
      let response = `http://schema.org/InStock`
      const qty = this.product?.qty
      const status = this.product?.status
      const default_show_stock_qty_threshold =
        this.product?.default_show_stock_qty_threshold
      if (status === 'active' && qty >= default_show_stock_qty_threshold) {
        response = `http://schema.org/InStock`
      }
      if (status === 'active' && qty < default_show_stock_qty_threshold) {
        response = `http://schema.org/LimitedAvailability`
      }
      if (status !== 'active') {
        response = `http://schema.org/OutOfStock`
      }
      return response
    },
    getSeoMicroOffer() {
      if (
        !this.product ||
        !this.product.id ||
        !this.product.name ||
        !this.product.retail_price
      )
        return null

      const priceValidUntil = dayjs()
        .add(1, 'year')
        .format('YYYY-MM-DDTHH:mm:ssZ[Z]')
      const data = {
        '@context': 'http://schema.org',
        '@type': 'Product',
        sku: this.product.id,
        mpn: this.product?.mpn || this.product.id,
        url: this.link,
        name: this.product.name || '',
        image: this.image || '',
        description: `${this.product.name} ${this.$t('product_description')}`,
        itemCondition: `http://schema.org/NewCondition`,

        offers: {
          '@type': 'Offer',
          availability: this.productAvailability,
          url: this.currentRout + this.$route.fullPath,
          price: this.product.retail_price,
          priceCurrency: 'AZN',
          priceValidUntil,
        },
      }
      if (this.reviews?.length > 0) {
        data.review = this.reviews.map(item => ({
          '@type': 'Review',
          author: {
            '@type': 'Person',
            name:
              item?.customer_name === ' ' || !item?.customer_name
                ? 'User'
                : item?.customer_name,
          },
          datePublished: this.date(item?.created_at),
          reviewBody:
            item?.text ||
            item?.messages?.[0]?.text ||
            item?.messages?.[1]?.text,
          name: this.product?.name,
          reviewRating: {
            '@type': 'Rating',
            bestRating: 5,
            ratingValue: item.score,
            worstRating: 1,
          },
        }))
      }
      if (this.productRating > 0 && this.productRatingCount > 0) {
        data.aggregateRating = {
          '@type': 'AggregateRating',
          ratingValue: Math.round(this.productRating),
          reviewCount: this.productRatingCount,
        }
      }
      if (this.isMultipleOffers) {
        data.offers = {
          '@type': 'AggregateOffer',
          availability: this.productAvailability,
          offerCount: this.product?.offers.length,
          highPrice: this.highPrice,
          lowPrice: this.lowPrice,
          priceCurrency: 'AZN',
        }
      }
      if (this.product.manufacturer) {
        data.brand = {
          '@type': 'Brand',
          name: this.product.manufacturer,
          url:
            this.currentRout +
            this.localePath({
              name: 'brands-brand',
              params: {
                brand: this.product.manufacturer,
              },
            }),
        }
      }
      return JSON.stringify(data)
    },
  },
}
